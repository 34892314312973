/**
 * 设备管理
 */
 import req from './index.js'

 export const equipmentOrganizationTree = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentOrganizationTree',param) };//设备结构树
 export const equipmentStaticBasicInfo = param => { return req.post('EMS.Thing.Database.EquipmentInfor_DR/Services/EquipmentStaticBasicInfo',param) };//设备静态信息查询---暂定
 export const equipmentStaticKPI = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentStaticKPI',param) };//设备玉珏三率图查询
 export const equipmentRealStatus = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentRealStatus',param) };//设备实时状态

 export const equipmentStaticTimeAnalysis = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentStaticTimeAnalysis',param) };//设备时间分析柱图查询
 export const equipmentBasicInfo = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentBasicInfo',param) };//设备基本信息查询

//  设备属性
 export const equipmentPropertyType = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPropertyType',param) };//设备专用属性类型选择接口
 export const equipmentProperty = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentProperty',param) };//设备专用属性查询
 export const equipmentPropertyTrend = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPropertyTrend',param) };//设备专用属性历史趋势查询
 export const equipmentPropertySelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPropertySelect',param) };//设备专用属性选择接口
 

//  时间片
 export const equipmentTimeSlice = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentTimeSlice',param) };//设备时间片
 export const equipmentTimeStatus = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentTimeStatus',param) };//设备时间片+
 export const equipmentTimeSliceAnalysis = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentTimeSliceAnalysis',param) };//设备时间片分析
 export const equipmentTimeOrganizationSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentTimeOrganizationSelect',param) };//时间片设备选择接口


//  kpi
 export const equipmentKpi = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentKPI',param) };//设备KPI查询
 export const equipmentKpiAnalysis = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentKpiAnalysis',param) };//设备KPI分析
 export const equipmentKpiSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentKpiSelect',param) };//设备KPI分析


//  报警分析
 export const equipmentAlarmInfoStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoStatistics',param) };//报警信息统计
 export const equipmentAlarmInfoRealTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoRealTable',param) };//实时报警信息表
 export const equipmentAlarmInfoHistoryTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoHistoryTable',param) };//历史报警信息表
 export const equipmentAlarmInfoLevelStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoLevelStatistics',param) };//报警等级数量统计
 export const equipmentAlarmInfoAcknowledge = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoAcknowledge',param) };//报警确认
 export const equipmentAlarmTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmTypeSelect',param) };//报警类型选择
 export const equipmentAlarmLevelSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmLevelSelect',param) };//报警等级选择
 export const equipmentAlarmInfoHistoryStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoHistoryStatistics',param) }; // 历史报警信息统计
 export const equipmentHistoryAlarmLevelSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentHistoryAlarmLevelSelect',param) }; // 历史报警等级选择框
 export const equipmentHistoryAlarmTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentHistoryAlarmTypeSelect',param) }; // 历史报警类型选择框
 export const equipmentAlarmAcknowledgeStatus = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmAcknowledgeStatus',param) }; // 实时报警确认选择框
 export const equipmentHistoryAlarmAcknowledgeStatus = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentHistoryAlarmAcknowledgeStatus',param) }; // 历史报警确认选择框
//  export const equipmentAlarmInfoStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentAlarmInfoStatistics',param) }; 


//  点检信息
export const equipmentPointInspectioStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPointInspectionStatistics',param) };//点检信息统计
export const equipmentPointInspectioRecordTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPointInspectionRecordTable',param) };//点检信息清单
export const equipmentPointInspectioDetailRecord = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPointInspectionDetailRecord',param) };//点检信息详细记录
export const equipmentPointInspectioTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentPointInspectioTypeSelect',param) };//点检类型选择

// 保养履历
export const equipmentMaintainStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentMaintainStatistics',param) };//保样信息统计
export const equipmentMaintainRecordTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentMaintainRecordTable',param) };//保养信息清单
export const equipmentMaintainDetailRecord = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentMaintainDetailRecord',param) };//保养信息详细记录
export const equipmentMaintainTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentMaintainTypeSelect',param) };//保养类型选择

// 维修履历
export const equipmentRepairStatistics = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentRepairStatistics',param) };//维修信息统计
export const equipmentRepairRecordTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentRepairRecordTable',param) };//维修信息清单
export const equipmentRepairDetailRecord = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentRepairDetailRecord',param) };//维修信息详细记录
export const equipmentRepairTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentRepairTypeSelect',param) };//维修类型选择

// 资料文档
export const equipmentDocumentRecord = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentDocumentRecord',param) };//资料文档查询
export const equipmentDocumentTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentDocumentTypeSelect',param) };//资料文档类型选择

// 备件信息
export const equipmentSparePartTable = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentSparePartTable',param) };//备件信息
export const equipmentSparePartTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/EquipmentSparePartTypeSelect',param) };//备件类型选择


// 报警分析
export const alarmAnalysisStatistics = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisStatistics',param) };//报警分析统计
export const alarmAnalysisTrend = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisTrend',param) };//报警分析趋势
export const alarmAnalysisBarChart = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisBarChart',param) };//报警分析柱图

export const alarmAnalysisOrganizationSelect = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisOrganizationSelect',param) };//根据权限选择组织
export const alarmAnalysisEquipmentTypeSelect = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisEquipmentTypeSelect',param) };//选择设备类型
export const alarmAnalysisDimensionalitySelect = param => { return req.post('EMS.Thing.Api.AlarmAnalysis/Services/AlarmAnalysisDimensionalitySelect',param) };//分析维度选择框



// 报警查询
export const alarmQueryOrganizationSelect = param => { return req.post('EMS.Thing.Api.AalrmQuery/Services/AlarmQueryOrganizationSelect',param) };//根据权限选择组织
export const alarmQueryAlarmTypeSelect = param => { return req.post('EMS.Thing.Api.AalrmQuery/Services/AlarmQueryAlarmTypeSelect',param) };//报警类型选择
export const alarmQueryAlarmLevelSelect = param => { return req.post('EMS.Thing.Api.AalrmQuery/Services/AlarmQueryAlarmLevelSelect',param) };//报警等级选择
export const alarmQueryAcknowledgeStatus = param => { return req.post('EMS.Thing.Api.AalrmQuery/Services/AlarmQueryAcknowledgeStatus',param) };//确认状态选择
export const alarmQueryList = param => { return req.post('EMS.Thing.Api.AalrmQuery/Services/AlarmQueryList',param) };//报警查询列表



export const EquipmentLevelConfigList = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLevelConfigList',param) };//测试接口



 //Kpi 
 export const kpiAnalysisEquipmentTypeAnalysis = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentTypeAnalysis',param) }; 
 export const kpiAnalysisEquipmentTypeAnalysisTrend = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentTypeAnalysisTrend',param) }; 
 export const kpiAnalysisEquipmentAnalysis = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentAnalysis',param) }; 
 export const kpiAnalysisEquipmentAnalysisTrend = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentAnalysisTrend',param) }; 
 //  export const kpiAnalysisLineAnalysis = param => { return req.post('EMS.Thing.Api.LineKpiAnalysis/Services/KpiAnalysisLineAnalysis',param) }; 
 //  export const kpiAnalysisLineAnalysisTrend = param => { return req.post('EMS.Thing.Api.LineKpiAnalysis/Services/KpiAnalysisLineAnalysisTrend',param) }; 
 //  export const kpiAnalysisWorkShopAnalysis = param => { return req.post('EMS.Thing.Api.WorkShopKpiAnalysis/Services/KpiAnalysisWorkShopAnalysis',param) }; 
 //  export const kpiAnalysisWorkShopAnalysisTrend = param => { return req.post('EMS.Thing.Api.WorkShopKpiAnalysis/Services/KpiAnalysisWorkShopAnalysisTrend',param) }; 
 export const kpiAnalysisEquipmentTypeSelect = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentTypeSelect',param) }; 
 export const kpiAnalysisEquipmentTypeKpiSelect = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentTypeKpiSelect',param) }; 
 export const kpiAnalysisEquipmentSelect = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentSelect',param) }; 
 export const kpiAnalysisEquipmentKpiSelect = param => { return req.post('EMS.Thing.Api.EquipmentKpiAnalysis/Services/KpiAnalysisEquipmentKpiSelect',param) }; 
 
 export const kpiAnalysisOrganizationWorkpieceSelect = param => { return req.post('EMS.Thing.Api.OrganizationKpiAnalysis/Services/KpiAnalysisOrganizationWorkpieceSelect',param) }; 
 export const kpiAnalysisOrganizationSelect = param => { return req.post('EMS.Thing.Api.OrganizationKpiAnalysis/Services/KpiAnalysisOrganizationSelect',param) }; 
 export const kpiAnalysisOrganizationIndicatorSelect = param => { return req.post('EMS.Thing.Api.OrganizationKpiAnalysis/Services/KpiAnalysisOrganizationIndicatorSelect',param) }; 
 export const kpiAnalysisOrganizationAnalysis = param => { return req.post('EMS.Thing.Api.OrganizationKpiAnalysis/Services/KpiAnalysisOrganizationAnalysis',param) }; 
 export const kpiAnalysisOrganizationAnalysisTrend = param => { return req.post('EMS.Thing.Api.OrganizationKpiAnalysis/Services/KpiAnalysisOrganizationAnalysisTrend',param) }; 


//  保养计划  
export const getMaintainPlanStatus = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanStatusQuery',param) }; //保养计划状态
export const getMaintainCategory = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainCategoryQuery',param) }; //保养类别
export const maintainPlanMultiAuthQueryLimit = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanMultiAuthQueryLimit',param) }; //保养计划筛选分页
export const maintainPlanQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanQuery',param) }; //保养计划筛选
export const maintainPlanInsert = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanInsert',param) }; //保养计划新增
export const maintainPlanLoopUnitQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanLoopUnitQuery',param) }; //保养计划新增--循环周期单位
export const maintainPlanUpdate = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanUpdate',param) }; //保养计划修改
export const maintainPlanDelete = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanDelete',param) }; //保养计划 删除
export const maintainPlanAuthorize = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainPlanAuthorize',param) }; //保养计划 审批

// 保养工单
export const maintainOrderMultiAuthQueryLimit = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderMultiAuthQueryLimit',param) }; //保养工单筛选 分页数据
export const maintainOrderQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderQuery',param) }; //保养工单筛选 所有数据
export const maintainOrderInsert = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderInsert',param) }; //保养工单 新增
export const maintainOrderDelete = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderDelete',param) }; //保养工单 删除
export const maintainOrderUpdate = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderUpdate',param) }; //保养工单 修改

// 保养相关的下拉框
export const maintainOrderStatusQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderStatusQuery',param) }; //保养工单 工单状态
export const maintainOrderExecGradeQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderExecGradeQuery',param) }; //保养工单 工单评分等级
export const maintainOrderExecLevelQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderExecLevelQuery',param) }; //保养工单 保养等级
export const multiAuthorizeResultQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MultiAuthorizeResultQuery',param) }; //审批结果
export const maintainExecPersonQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainExecPersonQuery',param) }; //保养人员
export const maintainOrderExecuteTypeQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderExecuteTypeQuery',param) }; //工单执行类型定义
export const maintainOrderFinishTypeQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderFinishTypeQuery',param) }; //工单结单类型定义
export const getMaintainExecGroup = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainExecGroupQuery',param) }; //获取保养班组列表
export const getMaintainUrgenLevel = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainUrgenLevelQuery',param) }; //获取保养紧急程度列表

// 
export const maintainOrderAuthorize = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderAuthorize',param) }; //保养工单 审批
export const maintainOrderDispatch = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderDispatch',param) }; //保养工单 派单
export const maintainOrderTake = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderTake',param) }; //保养工单 接单
export const maintainOrderExecute = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderExecute',param) }; //保养工单 执行
export const maintainOrderPause = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderPause',param) }; //保养工单 暂离
export const maintainOrderContinue = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderContinue',param) }; //保养工单 暂离结束
export const maintainOrderMaintainFinish = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderMaintainFinish',param) }; //保养工单 保养工单结单
export const maintainOrderVerify = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderVerify',param) }; //保养工单 验证
export const maintainOrderMarkEmpirical = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderMarkEmpirical',param) }; //保养工单 是否为经验性工单
export const maintainOrderMaintainGrade = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderMaintainGrade',param) }; //保养工单 工单评分


// 保养工单--备件
export const maintainOrderSparePartInsert = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderSparePartInsert',param) }; //保养工单使用备件 新增
export const maintainOrderSparePartDelete = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderSparePartDelete',param) }; //保养工单使用备件 删除
export const maintainOrderSparePartUpdate = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderSparePartUpdate',param) }; //保养工单使用备件 修改
export const maintainOrderSparePartQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderSparePartQuery',param) }; //保养工单使用备件筛选 所有数据
export const maintainOrderSparePartQueryLimit = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainOrderSparePartQueryLimit',param) }; //保养工单使用备件筛选 分页数据


// 保养记录
export const maintainRecordQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainRecordQuery',param) }; //保养记录
// 保养日历
export const maintainCalendarQuery = param => { return req.post('EMS.Thing.Api.MaintainManagement/Services/MaintainCalendarQuery',param) }; //保养日历




//故障报修
// export const failureReportQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportQuery',param) }; 
// export const failureReportInsert= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportInsert',param) }; 
// export const faultLevelQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FaultLevelQuery',param) }; 
// export const faultTypeQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FaultTypeQuery',param) }; 
// export const failureReportDelete= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportDelete',param) }; 
// export const failureReportUpdate= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportUpdate',param) }; 
export const failureReportQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportQuery',param) }; 
export const failureReportMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportMultiAuthQueryLimit',param) }; 
export const failureReportInsert= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportInsert',param) }; 
export const faultLevelQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FaultLevelQuery',param) }; 
export const faultTypeQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FaultTypeQuery',param) }; 
export const failureReportDelete= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportDelete',param) }; 
export const failureReportUpdate= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportUpdate',param) }; 
export const failureReportAuthorize= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureReportAuthorize',param) }; 
export const repairPlanGeneration= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanGeneration',param) }; 
export const repairOrderGeneration= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderGeneration',param) }; 
export const failureStatusQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/FailureStatusQuery',param) };

//维修计划

export const repairPlanAuthorize= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanAuthorize',param) }; 
export const repairPlanQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanQuery',param) }; 
export const repairPlanMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanMultiAuthQueryLimit',param) }; 
export const repairGroupQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairGroupQuery',param) }; 
export const repairTypeQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairTypeQuery',param) }; 
export const repairPlanUpdate= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanUpdate',param) }; 
export const repairPlanInsert= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanInsert',param) }; 
export const repairPlanDelete= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanDelete',param) }; 
export const repairUrgenLevel = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairUrgenLevelQuery',param) }; //获取保养紧急程度列表
export const repairPlanStatusQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairPlanStatusQuery',param) }; //获取保养紧急程度列表

//维修工单
export const repairOrderMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderMultiAuthQueryLimit',param) }; 
export const repairOrderQuery= param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderQuery',param) }; 
export const repairOrderInsert = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderInsert',param) }; 
export const repairOrderDelete = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderDelete',param) }; 
export const repairOrderUpdate = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderUpdate',param) }; 
export const repairOrderStatusQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderStatusQuery',param) }; 
export const repairOrderExecGradeQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderExecGradeQuery',param) };

export const repairOrderAuthorize = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderAuthorize',param) };
export const repairOrderDispatch = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderDispatch',param) };
export const repairOrderTake = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderTake',param) };
export const repairOrderPause = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderPause',param) };
export const repairOrderExecute = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderExecute',param) };
export const repairOrderContinue = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderContinue',param) };
export const repairOrderRepairFinish = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderRepairFinish',param) };
export const repairOrderVerify = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderVerify',param) };
export const repairOrderMarkEmpirical = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderMarkEmpirical',param) };
export const repairOrderRepairGrade = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderRepairGrade',param) };
export const repairExecPersonQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairExecPersonQuery',param) };
export const repairOrderFinishTypeQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderFinishTypeQuery',param) };
export const repairOrderExecuteTypeQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderExecuteTypeQuery',param) };
//记录
export const repairOrderRecordQueryLimit = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderRecordQueryLimit',param) };


//备件
export const repairOrderSparePartQueryLimit = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderSparePartQueryLimit',param) };
export const repairOrderSparePartQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderSparePartQuery',param) };
export const repairOrderSparePartUpdate = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderSparePartUpdate',param) };
export const repairOrderSparePartInsert = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderSparePartInsert',param) };
export const repairOrderSparePartDelete = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderSparePartDelete',param) };
export const repairUrgenLevelQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairUrgenLevelQuery',param) };
export const repairOrderExecLevelQuery = param => { return req.post('EMS.Thing.Api.RepairManagement/Services/RepairOrderExecLevelQuery',param) };


//点检管理

//点检计划
export const inspectionPlanDelete= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanDelete',param) }; //点检计划 删除
export const inspectionPlanMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanMultiAuthQueryLimit',param) }; //点检计划 筛选 分页数据
export const inspectionPlanInsert= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanInsert',param) }; //点检计划 新增
export const inspectionPlanQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanQuery',param) }; //点检计划筛选 所有数据
export const inspectionPlanUpdate= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanUpdate',param) }; //点检计划 修改

// 

//点检标准
export const inspectionStandardQueryLimit= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardQueryLimit',param) }; //点检标准筛选 分页数据
export const inspectionStandardQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardQuery',param) }; //点检标准筛选 所有数据
export const inspectionStandardDelete= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardDelete',param) }; //点检标准 删除
export const inspectionStandardUpdate= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardUpdate',param) }; //点检标准 修改

// 点检-下拉框
export const inspectionPlanTypeQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanTypeQuery',param) }; //点检计划类型
export const inspectionPlanLoopUnitQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionPlanLoopUnitQuery',param) }; //点检循环周期
export const inspectionGroupQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionGroupQuery',param) }; //点检班组筛选
export const inspectionOrderTypeQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderTypeQuery',param) }; //点检工单类型 返回值
export const inspectionOrderStatusQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderStatusQuery',param) }; //点检工单状态 返回值






export const uploadInspectionImage= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/UploadInspectionImage',param) }; //添加标准-上传图片
export const inspectionLoadImage= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionLoadImage',param) }; // 获取图片

//点检标准详情 
export const inspectionStandardDetailQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardDetailQuery',param) }; //点检标准详情筛选 所有数据
export const inspectionStandardDetailQueryLimit= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardDetailQueryLimit',param) }; //点检标准详情筛选 分页数据
export const inspectionStandardDetailUpdate= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardDetailUpdate',param) }; //点检标准详 修改
export const inspectionStandardDetailInsert= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardDetailInsert',param) }; //点检标准详情 新增



//点检工单
export const inspectionOrderQueryLimit= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderQueryLimit',param) }; //点检工单筛选 分页数据
export const inspectionOrderDelete= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderDelete',param) }; //点检工单 删除
export const inspectionOrderQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderQuery',param) }; //点检工单筛选 所有数据
export const inspectionOrderUpdate= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderUpdate',param) }; //点检工单 修改
export const inspectionOrderTake= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderTake',param) }; //点检工单 接单
export const inspectionOrderExecute= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderExecute',param) }; //点检工单 执行
export const inspectionOrderPause= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderPause',param) }; //点检工单 暂离
export const inspectionOrderContinue= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderContinue',param) }; //点检工单 暂离结束
export const inspectionOrderInspectFinish= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionOrderInspectFinish',param) }; // 点检工单 结单


// 点检下拉框接口
export const inspectionStandardTypeQuery= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardTypeQuery',param) }; //点检标准类型
export const inspectionStandardInsert= param => { return req.post('EMS.Thing.Api.InspectionManagement/Services/InspectionStandardInsert',param) }; //点检标准 新增



// 备件台账接口
//设备级联下拉选
export const productionInforEquipmentSelect = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentSelect',param) };
// 备件台账-筛选分页数据
export const sparePartStockQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockQueryLimit',param) };
// 备件台账-新增
export const sparePartStockInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockInsert',param) };
// 备件台账-删除
export const sparePartStockDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockDelete',param) };
// 备件台账-修改
export const sparePartStockUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockUpdate',param) };
// 备件台账-筛选所有数据
export const sparePartStockQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockQuery',param) };
// 备件台账-单位 更换周期
export const sparePartStockReplaceCycleQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockReplaceCycleQuery',param) };

//备件出库记录接口
//仓库下拉选择框
export const sparePartWarehouseQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartWarehouseQuery',param) };
// 备件出库单 新增
export const sparePartOutWarehouseInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseInsert',param) };
// 备件出库单 删除
export const sparePartOutWarehouseDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDelete',param) };
// 备件出库单 修改
export const sparePartOutWarehouseUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseUpdate',param) };
// 备件出库单筛选 所有数据
export const sparePartOutWarehouseQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseQuery',param) };
// 备件出库单筛选 分页数据
export const sparePartOutWarehouseQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseQueryLimit',param) };
// 备件出库单筛选 审批内容 分页数据
export const sparePartOutWarehouseMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseMultiAuthQueryLimit',param) };
// 备件出库单 审批
export const sparePartOutWarehouseAuthorize= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseAuthorize',param) };
// 备件出库单详情 新增
export const sparePartOutWarehouseDetailInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDetailInsert',param) };
// 备件出库单详情 删除
export const sparePartOutWarehouseDetailDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDetailDelete',param) };
// 备件出库单详情 修改
export const sparePartOutWarehouseDetailUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDetailUpdate',param) };
// 备件出库单详情筛选 所有数据
export const sparePartOutWarehouseDetailQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDetailQuery',param) };
// 备件出库单详情筛选 分页数据
export const sparePartOutWarehouseDetailQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartOutWarehouseDetailQueryLimit',param) };
// 备件入库单 新增
export const sparePartInWarehouseInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseInsert',param) };
// 备件入库单 删除
export const sparePartInWarehouseDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDelete',param) };
// 备件入库单 修改
export const sparePartInWarehouseUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseUpdate',param) };
// 备件入库单筛选 所有数据
export const sparePartInWarehouseQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseQuery',param) };
// 备件入库单筛选 分页数据
export const sparePartInWarehouseQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseQueryLimit',param) };
// 备件入库单筛选 审批内容 分页数据
export const sparePartInWarehouseMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseMultiAuthQueryLimit',param) };
// 备件入库单 审批
export const sparePartInWarehouseAuthorize= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseAuthorize',param) };
// 备件入库单详情 新增
export const sparePartInWarehouseDetailInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDetailInsert',param) };
// 备件入库单详情 删除
export const sparePartInWarehouseDetailDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDetailDelete',param) };
// 备件入库单详情 修改
export const sparePartInWarehouseDetailUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDetailUpdate',param) };
// 备件入库单详情筛选 所有数据
export const sparePartInWarehouseDetailQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDetailQuery',param) };
// 备件入库单筛选 所有数据
export const sparePartInWarehouseDetailQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInWarehouseDetailQueryLimit',param) };
// 备件盘点单 新增
export const sparePartInventoryInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryInsert',param) };
// 备件盘点单 删除
export const sparePartInventoryDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDelete',param) };
// 备件盘点单 修改
export const sparePartInventoryUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryUpdate',param) };
// 备件盘点单筛选 所有数据
export const sparePartInventoryQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryQuery',param) };
// 备件盘点单筛选 分页数据
export const sparePartInventoryQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryQueryLimit',param) };
// 备件盘点单筛选 审批内容 分页数据
export const sparePartInventoryMultiAuthQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryMultiAuthQueryLimit',param) };
// 备件盘点单筛选 审批
export const sparePartInventoryAuthorize= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryAuthorize',param) };
// 备件盘点单详情 新增
export const sparePartInventoryDetailInsert= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDetailInsert',param) };
// 备件盘点单详情 删除
export const sparePartInventoryDetailDelete= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDetailDelete',param) };
// 备件盘点单详情 修改
export const sparePartInventoryDetailUpdate= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDetailUpdate',param) };
// 备件盘点单详情筛选 所有数据
export const sparePartInventoryDetailQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDetailQuery',param) };
// 备件盘点单详情筛选 分页数据
export const sparePartInventoryDetailQueryLimit= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartInventoryDetailQueryLimit',param) };
// 备件类型下拉选
export const sparePartTypeQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartTypeQuery',param) };
// 备件单位下拉选
export const sparePartUnitQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartUnitQuery',param) };
// 备件单位更换周期下拉选
export const sparePartStockReplaceCycleUnitQuery= param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartStockReplaceCycleUnitQuery',param) };




//备件领用
export const sparePartPickInsert = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickInsert',param) };
export const sparePartPickDelete = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDelete',param) };
export const sparePartPickUpdate = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickUpdate',param) };
export const sparePartPickMultiAuthQueryLimit = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickMultiAuthQueryLimit',param) };
export const sparePartPickQuery = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickQuery',param) };
export const multiAuthorizeResultQuery1 = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/MultiAuthorizeResultQuery',param) };
export const sparePartPickAuthorize = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickAuthorize',param) };
export const sparePartPickDetailInsert = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDetailInsert',param) };
export const sparePartPickDetailDelete = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDetailDelete',param) };
export const sparePartPickDetailUpdate = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDetailUpdate',param) };
export const sparePartPickDetailQuery = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDetailQuery',param) };
export const sparePartPickDetailQueryLimit = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPickDetailQueryLimit',param) };
export const equipmentLedgerDepartment = param => { return req.post('EMS.Thing.Api.Setting/Services/EquipmentLedgerDepartment',param) };

//备件购置
export const sparePartPurchaseMultiAuthQueryLimit = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseMultiAuthQueryLimit',param) };
export const sparePartPurchaseQuery = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseQuery',param) };
export const sparePartPurchaseUpdate = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseUpdate',param) };
export const sparePartPurchaseAuthorize = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseAuthorize',param) };
export const sparePartPurchaseInsert = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseInsert',param) };
export const sparePartPurchaseDelete = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDelete',param) };

export const sparePartPurchaseDetailQueryLimit = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDetailQueryLimit',param) };
export const sparePartPurchaseDetailQuery = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDetailQuery',param) };
export const sparePartPurchaseDetailInsert = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDetailInsert',param) };
export const sparePartPurchaseDetailDelete = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDetailDelete',param) };
export const sparePartPurchaseDetailUpdate = param => { return req.post('EMS.Thing.Api.SparePartManagement/Services/SparePartPurchaseDetailUpdate',param) };





//数据加工中心-数据驾驶舱
export const equipmentDashBoardGetTitle= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentDashBoardGetTitle',param) }; //获取看板标题
export const organizationSelect= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/OrganizationSelect',param) }; //组织架构选择
export const equipmentStatus= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentStatus',param) }; //设备状态
export const equipmentWorkpieceinfor= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentWorkpieceinfor',param) }; //工件信息
export const equipmentProgaminfor= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentProgaminfor',param) }; //程序信息
export const equipmentRunRecord= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentRunRecord',param) }; //运行记录
export const equipmentCoordinateData= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentCoordinateData',param) }; //坐标数据
export const equipmentKPI= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentKPI',param) }; //关键指标
export const equipmentPropertyTrendCNC= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentPropertyTrend',param) }; //数据趋势
export const equipmentDashBoardTimeSlice= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentTimeSlice',param) }; //设备状态时间片
export const equipmentInformation= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentInformation',param) }; //设备信息
export const equipmentCraftData= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentCraftData',param) }; //工艺数据
export const equipmentAralmData= param => { return req.post('EMS.Thing.Api.DashBoard.CNC/Services/EquipmentAralmData',param) }; //报警记录


//数据驾驶舱-焊接站
export const equipmentDashBoardRobotGetTitle= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentDashBoardGetTitle',param) }; //获取看板标题
export const organizationSelectRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/OrganizationSelect',param) }; //组织架构选择
export const equipmentStatusRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentStatus',param) }; //设备状态
export const equipmentWorkpieceinforRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentWorkpieceinfor',param) }; //工件信息
export const equipmentProgaminforRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentProgaminfor',param) }; //程序信息
export const equipmentRunRecordRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentRunRecord',param) }; //运行记录
export const equipmentCoordinateDataRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentCoordinateData',param) }; //坐标数据
export const equipmentKPIRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentKPI',param) }; //关键指标
export const equipmentPropertyTrendRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentPropertyTrend',param) }; //数据趋势
export const equipmentDashBoardTimeSliceRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentTimeSlice',param) }; //设备状态时间片
export const equipmentInformationRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentInformation',param) }; //设备信息
export const equipmentCraftDataRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentCraftData',param) }; //工艺数据
export const equipmentAralmDataRobot= param => { return req.post('EMS.Thing.Api.DashBoard.Robot/Services/EquipmentAralmData',param) }; //报警记录


//数据驾驶舱-喷涂线
export const equipmentDashBoardSprayGetTitle= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentDashBoardGetTitle',param) }; //获取看板标题
export const organizationSelectSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/OrganizationSelect',param) }; //组织架构选择
export const equipmentStatusSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentWorkpiece',param) }; //设备状态
export const equipmentWorkpieceinforSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentWorkpieceinfor',param) }; //工件信息
export const equipmentCraftDataSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentCraftData',param) }; //工艺数据
export const equipmentEnvironmentData= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentEnvironmentData',param) }; //环境数据
export const equipmentAralmDataSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentAralmData',param) }; //报警记录
export const equipmentUtilization= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentUtilization',param) }; //节拍利用率
export const equipmentFaultRate= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentFaultRate',param) }; //故障率
export const equipmentPropertyTrendSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentPropertyTrend',param) }; //数据趋势
// export const equipmentTrendSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentTrend',param) }; //
// export const equipmentLayoutDataSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Spray/Services/EquipmentLayoutData',param) }; //


//数据驾驶舱-装配线
export const equipmentDashBoardFitGetTitle= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentDashBoardGetTitle',param) }; //获取看板标题
// export const organizationSelectSpray= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/OrganizationSelect',param) }; //组织架构选择
export const equipmentStatusFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentLineinfor',param) }; //设备状态
export const equipmentFittingData= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentFittingData',param) }; //装配信息
export const equipmentCraftDataFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentCraftData',param) }; //加注信息
export const equipmentAralmDataFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentAralmData',param) }; //报警记录
export const equipmentUtilizationFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentUtilization',param) }; //节拍利用率
export const equipmentFaultRateFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentFaultRate',param) }; //故障率
export const equipmentTighteningData= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentTighteningData',param) }; //拧紧信息
export const equipmentAnomalyDiagnosis= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentAnomalyDiagnosis',param) }; //异常诊断
export const equipmentLayoutDataFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentLayoutData',param) }; //
export const equipmentTrendFit= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentTrend',param) }; //
 
//振动分析报警&&诊断
export const alarmDataQuery = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AlarmDataQuery',param) }; 
export const alarmDataQueryLimit = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AlarmDataQueryLimit',param) }; 
export const diagnosisDataQuery = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/DiagnosisDataQuery',param) }; 
export const diagnosisDataQueryLimit = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/DiagnosisDataQueryLimit',param) }; 
export const diagnosisFactorDtos = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/DiagnosisFactorDtos',param) }; 


export const advancedAnalysisToSensorDataQueryLimit = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AdvancedAnalysisToSensorDataQueryLimit',param) }; 
export const advancedAnalysisRMSTrend = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AdvancedAnalysisRMSTrend',param) }; 
export const advancedAnalysisFrequencyDomainTrend = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AdvancedAnalysisFrequencyDomainTrend',param) }; 
export const advancedAnalysisTemperatureTrend = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/AdvancedAnalysisTemperatureTrend',param) }; 
export const returnJumpURL = param => { return req.post('EMS.Thing.Database.iFLYTEK/Services/ReturnJumpURL',param) }; 

